.container > span {
  font-size: 1rem;
  color: #ffffff;
  font-weight: bold;
}

.container-game {
  height: 180px;
}

h3 {
  color: #ffffff;
}

.letterContainer input {
  width: 40px;
  height: 40px;
  text-align: center;
}

.wordContainer {
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 85%;
}

.wordContainer span {
  font-size: 30px;
  line-height: 1.5;
  border: 2px solid #000;
  width: 40px;
  height: 50px;
  text-transform: uppercase;
  background-color: #ffffff;
  color: #000;
  margin: 0;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100px;
}

form input {
border: 2px solid #000;
}