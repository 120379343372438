/* <-- variaveis css --> */
:root {
  --roxo-padrao: #6C3DFB;
  --roxo-padrao-hover: #4627a5;

}

/* <-- tags --> */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background-image: url('./img/background.png');
  background-position: none;
  background-size: cover;
}

button, a {
  color: white;
  background-color: var(--roxo-padrao);
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  border-radius: 50px;
  width: 180px;
  height: 40px;
  border: none;
  box-shadow: 0px 11px 41px -15px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 11px 41px -15px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 11px 41px -15px rgba(0,0,0,0.75);
  cursor: pointer;
}

button:hover, a:hover {
  background-color: var(--roxo-padrao-hover);
}

/* <-- classes --> */
.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  height: 100vh;
}

@media (min-width: 545px) {
  body {
    background-image: url('./img/background-ipads.png');;
  }
}

@media (min-width: 975px) {
  body {
    background-image: url('./img/background-desktop.png');;
  }
}